import React, { useEffect } from "react";
import Logo from "../../assets/workoutLogo.png";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { useNavigate } from "react-router-dom";

let sticky;
let navbar;

function Appbar(props) {

  const handleSticky = () => {
    if (window.pageYOffset > sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky")
    }
  }

  useEffect(() => {
    navbar = document.getElementById('main-navigation');
    sticky = navbar.offsetTop;

    window.addEventListener('scroll', handleSticky);
  }, []);

  const navigate = useNavigate();
  return (
    <Navbar id="main-navigation" className="navbar">
      <Container className="container">
        <div>
          <Navbar.Brand href="/">
            <img className="logo" src={Logo} alt="Logo" />
          </Navbar.Brand>
        </div>
        {props.showMenu ? (
          <div className="menu">
            <Nav className="nav">
              <Nav.Link className="text-black" href="#about">
                About
              </Nav.Link>
              <Nav.Link className="text-black" href="#wod">
                WodLens
              </Nav.Link>
              <Nav.Link className="text-black" href="#contact">
                Contact
              </Nav.Link>
            </Nav>
          </div>
        ) : (
          <div />
        )}

        <Button className="button" onClick={() => navigate("/signup")}>Sign Up</Button>
      </Container>
    </Navbar>
  );
}

export default Appbar;
