import React from "react";
import "./slider.css";
import slider1 from "../../assets/slider1.png";
import slider2 from "../../assets/slider2.png";
import slider3 from "../../assets/slider3.png";
import slider4 from "../../assets/slider4.png";
import Scrollbars from "react-custom-scrollbars";
const Slider = () => {
  const images = [slider1, slider2, slider3, slider4];
  return (
    <div className="boxSlider">
      <h1 className="sliderHeading">#WorkoutLogApp</h1>
      <Scrollbars style={{ height: "300px", width: "100%" }}>
        <div class="row flex-nowrap">
          {images.map((image, key) => {
            return (
              <img
              alt=""
                key={key}
                style={{
                  height: "250px",
                  width: "250px",
                  objectFit: "cover",
                  paddingLeft: "50px",
                  paddingBottom: "20px",
                }}
                src={image}
              />
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default Slider;
