// import React, { useState } from "react";
// import gradient from "../../assets/signup.png";
// import workout from "../../assets/w.png";
// import icon1 from "../../assets/icon1.png";
// import icon2 from "../../assets/icon2.png";
// import icon3 from "../../assets/icon3.png";
// import logo from "../../assets/smallLogo.png";
// import logoL from "../../assets/workoutLogo.png";
// import logoWhite from "../../assets/logoWhite.png";
// import store from "../../assets/store.png";
// import Image from "react-bootstrap/Image";
// import { Button, Form } from "react-bootstrap";
// import "./signup.css";
// import MailchimpSubscribe from "react-mailchimp-subscribe";


// const Signup = () => {

//   const postUrl = `https://app.us4.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

//   const [email, setEmail] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');

//   return (
//     <MailchimpSubscribe
//       url={postUrl}
//       render={({ subscribe, status, message }) => (
//         <div className="container1">
//           <div className="box">

//             <Image className="image" src={workout} fluid={true} />
//             <Image className="image" src={gradient} fluid={true} />
//             <Image src={logoWhite} style={{
//               position: "absolute",
//               left: "10px", top: "10px",
//             }} width={150} />
//           </div>
//           {status === "success" ? (
//             <div className="sent-box">
//               <img src={logoL} className="logoL" alt="" />
//               <div className="box4h" />
//               <h1 className="heading2">Success - Check your email</h1>
//               <div className="box4h" />
//               <img src={logo} alt="" />
//               <img src={store} alt="" />
//             </div>
//           ) : (
//             <div className="form-box">
//               <img src={logoL} className="logoL" alt="" />
//               <h3 className="heading1">Try WorkoutLog Premium for 7 days free!</h3>
//               <div className="feature-box">
//                 <img src={icon1} height={40} width={60} alt="" />
//                 <h2 className="title">Journal and Track Unlimited workouts.</h2>
//               </div>
//               <div className="feature-box">
//                 <img src={icon2} height={40} width={60} alt="" />
//                 <h2 className="title">Designed for any sport or fitness style.</h2>
//               </div>
//               <div className="feature-box">
//                 <img src={icon3} height={40} width={60} alt="" />
//                 <h2 className="title">
//                   Get Insights on your fitness trends and growth.
//                 </h2>
//               </div>
//               <Form className="form">
//                 <Form.Control
//                   placeholder="Enter first name"
//                   className="textfield1"
//                   onChange={(e) => setFirstName(e.value)}
//                   value={firstName}
//                 />
//                 <Form.Control
//                   placeholder="Enter last name"
//                   className="textfield1"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.value)}
//                 />
//                 <Form.Control placeholder="Enter Email " className="textfield1" onChange={(e) => setEmail(e.value)} value={email} />
//               </Form>
//               {status === "sending" ? <Button className="button1">
//                 Signing up...
//               </Button> : <Button onClick={(e) => {
//                 e.preventDefault();
//                 console.log("signing up");
//                 email &&
//                   firstName &&
//                   lastName &&
//                   email.indexOf("@") > -1 &&
//                   subscribe({
//                     MERGE0: email,
//                     MERGE1: firstName,
//                     MERGE2: lastName,
//                   })
//                   console.log("done");
//               }} className="button1">
//                 Get 7 Day Premium Access
//               </Button>}
//             </div>
//           )}
//         </div>
//       )}
//     />

//   );
// };

// export default Signup;

import React, { useState } from "react";
import gradient from "../../assets/signup.png";
import workout from "../../assets/w.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import logo from "../../assets/smallLogo.png";
import logoL from "../../assets/workoutLogo.png";
import logoWhite from "../../assets/logoWhite.png";
import playStore from "../../assets/google_play.svg";
import appleStore from "../../assets/app_store.svg";
import Image from "react-bootstrap/Image";
import { Button, Form } from "react-bootstrap";
import "./signup.css";


const Signup = () => {

  const [emailSent, setEmailSent] = useState(false);
  function sendEmail() {
    setEmailSent(true);
  }

  return (
    <div className="container1">
      <div className="box">

        <Image className="image" src={workout} fluid={true} />
        <Image className="image" src={gradient} fluid={true} />
        <Image src={logoWhite} style={{
          position: "absolute",
          left: "10px", top: "10px",
        }} width={150} />
      </div>
      {emailSent ? (
        <div className="sent-box">
          <img src={logoL} className="logoL" alt="" />
          <div className="box4h" />
          <h1 className="heading2">Success - Check your email</h1>
          <div className="box4h" />
          <img src={logo} alt="" />
          <a href="https://play.google.com/store/apps/details?id=com.flutter.wodjournal" target="_blank" width={250} rel="noreferrer">
            <img src={playStore} alt="" width={250} />
          </a>
          <a target="_blank" href="https://apps.apple.com/in/app/workout-log-training-journal/id1572317389" rel="noreferrer">
            <img src={appleStore} alt="" width={250} />
          </a>
        </div>
      ) : (
        <div className="form-box">
          <img src={logoL} className="logoL" alt="" />
          <h3 className="heading1">Try WorkoutLog Premium for 7 days free!</h3>
          <div className="feature-box">
            <img src={icon1} height={40} width={60} alt="" />
            <h2 className="title">Journal and Track Unlimited workouts.</h2>
          </div>
          <div className="feature-box">
            <img src={icon2} height={40} width={60} alt="" />
            <h2 className="title">Designed for any sport or fitness style.</h2>
          </div>
          <div className="feature-box">
            <img src={icon3} height={40} width={60} alt="" />
            <h2 className="title">
              Get Insights on your fitness trends and growth.
            </h2>
          </div>
          <Form className="form">
            <Form.Control
              placeholder="Enter first name"
              className="textfield1"
            />
            <Form.Control
              placeholder="Enter last name"
              className="textfield1"
            />
            <Form.Control placeholder="Enter Email " className="textfield1" />
          </Form>
          <Button onClick={sendEmail} className="button1">
            Get 7 Day Premium Access
          </Button>  </div>
      )}
    </div>
  );
};

export default Signup;