import React from "react";
import Feature from "../../assets/feature.png";
import cycle from "../../assets/cycle.png";
import run from "../../assets/running.png";
import cross from "../../assets/cross.png";
import "./Features.css";
import bell from "../../assets/bell.png";
import circle from "../../assets/circle.png";
import timer from "../../assets/timer.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay} from "swiper";
import 'swiper/css';
import 'swiper/css/autoplay'
const Features = () => {
  return (
    <div className="boxFeature">
      <div className="boxRelative">
      <Swiper slidesPerView={1} autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay,]}
        >
        <SwiperSlide >
          {" "}
          
            <img className="imageFeature" src={Feature} alt="" />
            <h4 className="headingFeature">Built for any sport</h4>
            <h1 className="headingCenter">Weightlifting</h1>
         
        </SwiperSlide>
        <SwiperSlide>
          {" "}
        
            <img className="imageFeature" src={run} alt=""/>
            <h4 className="headingFeature">Built for any sport</h4>
            <h1 className="headingCenter">Running</h1>
         
        </SwiperSlide>
        <SwiperSlide>
          {" "}
     
            <img className="imageFeature" src={cross} alt=""/>
            <h4 className="headingFeature">Built for any sport</h4>
            <h1 className="headingCenter">Cross Training</h1>
       
        </SwiperSlide>
        <SwiperSlide>
          {" "}
        
            <img className="imageFeature" src={cycle} alt=""/>
            <h4 className="headingFeature">Built for any sport</h4>
            <h1 className="headingCenter">Cycling</h1>
        
        </SwiperSlide>
      </Swiper>
      </div>
      <div className="lineFeature" />
      <div className="box2Feature">
        <div>
          <div className="headingTitleBox">
            <img src={bell} height={40} alt=""/>
            <h5 className="cardTitle">Daily Reminders</h5>
          </div>

          <h6 className="cardSubTitle">
            Add Custom Reminders to Track Workouts when you Want.
          </h6>
        </div>
        <div>
          <div className="headingTitleBox">
            <img src={circle} height={40} alt=""/>
            <h5 className="cardTitle">Fitness Goals</h5>
          </div>

          <h6 className="cardSubTitle">
            Set a Goal For How Many Times You want To Workout This Month.
          </h6>
        </div>
        <div>
          <div className="headingTitleBox">
            <img src={timer} height={40} alt=""/>
            <h5 className="cardTitle">Custom Timers</h5>
          </div>

          <h6 className="cardSubTitle">
            Do an AMRAP and For Time Workout Back to Back.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Features;
