import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import "./Faq.css";
const Faq = () => {
  const [emailSent, setEmailSent] = useState(false);
  return (
    <div className="flexBox">
      <div className="contact-box">
        {emailSent ? (
          <div>
            <h1 style={{
              fontWeight: "700",
              fontSize: "25px",
            }}>Message Sent!</h1>
            <h6 className="font-family" style={{
              fontWeight: "700",
              fontSize: "18px",

            }}>We'll get in touch soon!</h6>
          </div>
        ) : (
          <div>
            <h4 className="contact-us">Contact Us</h4>
            <div className="faq-form">
              <input className="text-field-faq" placeholder="Full Name" />

              <input placeholder="email" className="text-field-faq" />

              <input placeholder="Message" className="text-field-faq" />

              <button onClick={() => setEmailSent(true)} className="button-faq">Send Message</button>
            </div>
          </div>
        )}
      </div>
      <div className="faq-box">
        <h1 className="faq-heading">Your Questions Answered</h1>
        <Accordion
          flush={true}
          style={{
            width: "100%",
          }}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h6 className="accordian-heading">What is Workout Log?</h6>
            </Accordion.Header>
            <Accordion.Body>
              Workout Log is a workout journal designed to track any sport from weightlifting and bodybuilding to swimming and CrossFit. With a very easy-to-use interface, it is an excellent way to journal your workouts without all the distractions other fitness apps have.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h6 className="accordian-heading">Can I Share my Workouts?</h6>
            </Accordion.Header>
            <Accordion.Body>
              Yes! After you create a workout or log a score you will be given the option to share your workout in a variety of ways. Including: <br />
              - Instagram <br />
              - Facebook <br />
              - Twitter <br />
              - Messages <br />
              You will also get a direct link to each workout so that you can share that for other athletes to log.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h6 className="accordian-heading">How much premium cost?</h6>
            </Accordion.Header>
            <Accordion.Body>
              Workout Log offers two premium plans. The monthly plan is $4.99 and the yearly plan is $49.99.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h6 className="accordian-heading">Is it a training plan?</h6>
            </Accordion.Header>
            <Accordion.Body>
              No - Workout Log is a personal workout tracker, not a training plan.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h6 className="accordian-heading">
                Can you Export your workouts?
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              Premium users have the option to export a log of all their workouts as a PDF.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <h6 className="accordian-heading">
                Can I user Workout log for any workout?
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              Yes! With our easy-to-use interface, you can track any sport you want. Customize your experience to your sport and more!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <h6 className="accordian-heading">
                Can my coach/gym use Workout Log?
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              At this moment Workout Log is not a gym or coaching software. If you are interested in using Workout Log for your gym or training program please reach out to us at: <a href="mailto:team@workoutlog.app">team@workoutlog.app</a>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
