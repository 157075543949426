import React from 'react'
import Background from "../../assets/background1.png";
import "./landing.css"
const Landing = () => {
  return (
    <div
    className='boxLanding'>
    <img className='imageLanding' alt="" src={Background}/>
    <h1
     className='heading'>
      Unlock Your
      <br /> Fitness Potential
    </h1>
    <div
     className='caption-box'
    >
      <h1
       className='caption-heading'
      >
        Take your training to the next level with all features for <span className='w800'>7
        days free</span>
      </h1>
      <div className='form-box-landing'>
        <input className='textfieldLanding'
        placeholder='Enter Email'
        />
        <button className='buttonLanding'>Sign Up</button>
      </div>
    </div>
  </div>
  )
}

export default Landing