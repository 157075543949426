import React from "react";
import Landing from "./Landing/Landing";
import Info from "./Info/Info";
import Features from "./Features/Features";
import Wod from "./Wod/Wod";
import Faq from "./Faq/Faq";
import './component.css'
import Slider from "./Slider/slider";
function MainComponent() {
  return (
    <div
    className="mainBody"
      style={{
        width: "100%",
        margin: "0px",
        padding: "0px",
      }}
    >
      <Landing />
      <div id="about">
        <Info />
      </div>
      <Features />
      <div id="wod">
        <Wod />
      </div>
      <div id="contact">
        <Faq />
      </div>
      <Slider/>
    </div>
  );
}

export default MainComponent;
