import Footer from "./components/Footer/Footer";
import MainComponent from "./components/MainComponent";
import Appbar from "./components/Navbar/Navbar";

function App() {
  return (
    <div
    >
      <Appbar showMenu={true}/>
      <MainComponent />
      <Footer />
    </div>
  );
}

export default App;
