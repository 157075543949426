import React from "react";
import Device from "../../assets/device1.png";
import Device2 from "../../assets/device2.png";
import StatCard from "./../StatCard";
import dumbell from "../../assets/dumbell.png"
import account from "../../assets/account.png"
import triangle from "../../assets/tri.png"
import square from "../../assets/sq.png"
import circle from "../../assets/ci.png"
import apple from "../../assets/apple.png"
import android from "../../assets/android.png"

import "./Info.css";
const Info = () => {
  return (
    <div className="containerInfo">
      <div className="box1">
        <h1 className="headingInfo">Growing Quickly...</h1>
        <div className="space10" />
        <div className="statboxAlign">
          <StatCard heading={"Global Users"} count={718} image={account}/>
          <StatCard heading={"Workouts Created"} count={
            "1,548"} image={dumbell}/>
        </div>
      </div>
      <div className="imageInfo1">
        <img src={Device} height={450} alt="" />
      </div>
      <div className="boxInfo2">
        <div
        className="info2Content"
        >
          <h1 className="wod">
            WOD
            <br />
            WOD
            <br />
            WOD
          </h1>
          <div className="imageInfo2">
            <img src={Device2} height={550} alt="" />
          </div>
          <div />
          <div
            className="textBoxInfo"
          >
            <h1 className="headingInfo2">Create Any Workout</h1>
            <p className="subheadingInfo">
              Workout Log is designed so that atheleted from <br />
              any sport can track and share their workouts.
            </p>
            <p className="subheadingInfo">
              Out Goal is to create a universal platform <br />
              that gives atheletes control of not only their <br />
              results, but their workouts as well.
            </p>
            <div style={{display:"flex",gap:"15px", alignItems:'center',justifyContent:'center'}}>
            <h5 className="available">Available on </h5>
            <a href="https://play.google.com/store/apps/details?id=com.flutter.wodjournal"><img src={android} height={40} alt=""/></a>
            <a href="https://apps.apple.com/in/app/workout-log-training-journal/id1572317389"><img src={apple} height={40} alt=""/></a>
            </div>
            
          </div>
        </div>
        <div className="imageInfoPhone">
        <img src={Device2} height={450} alt="" />
      </div>
        <div
          style={{
            height: "2px",
            width: "100%",
            backgroundColor: "#707070",
            margin: "0px 0px 20px 0px",
          }}
        />
        <div
         className="featureBoxInfo"
        >
          <div />
          <div
            className="gridInfo"
          >
            <div className="infoBoxInfo"
            ><img src={triangle} height={30} alt=""/> <h4 className="titleInfo"> Optimize Training</h4></div>
            <div className="infoBoxInfo"
            ><img src={square} height={30} alt=""/> <h4 className="titleInfo"> Build Consistency</h4></div>
            <div className="infoBoxInfo"
            ><img src={circle} height={30} alt=""/> <h4 className="titleInfo"> Share With Friends</h4></div>
            <h4 className="titleInfo">
              Training Accountibilty come with <br /> Tracking your workouts
              daily
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
