import React from "react";
import './Info/Info.css'
const StatCard = (props) => {
  return (
    <div
    className="statBox"
    >
      <div style={{
        display:"flex",
        gap:"20px",
        alignContent:"center"
      }}>
        <img src={props.image} alt="" height={20}/>        
        <h3
         className="statHeading"
        >
          {props.heading}
        </h3>
      </div>

      <h2
      className="statCount"
      >
        {props.count}
      </h2>
    </div>
  );
};

export default StatCard;
