import React from "react";
import "./footer.css";
import fb from "../../assets/fb.png";
import insta from "../../assets/insta.png";
import twitter from "../../assets/twit.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="boxFooter">
      <div className="box2Footer">
        <div>
          <h6 className="titleFooter">Software</h6>
          <Link className="subHeadingFooter" to={"/signup"}>
            <h6 className="subHeadingFooter">Sign up</h6>
          </Link>
          <Link className="subHeadingFooter" to={"/terms-conditions"}>
            <h6 className="subHeadingFooter">Terms of Use</h6>
          </Link>
          <Link className="subHeadingFooter" to={"/privacy-policy"}>
            <h6 className="subHeadingFooter">Privacy Policy</h6>
          </Link>
        </div>
        <div>
          <h6 className="titleFooter">Community</h6>
          <a className="subHeadingFooter" href="#contact">
            <h6 className="subHeadingFooter">Contact</h6>
          </a>
        </div>
        <div></div>
        <div>
          <div className="social-text">
            <h6 className="titleFooter">Socials</h6>
            <h6 ><a className="subHeadingFooter" href="https://www.instagram.com/workoutlog.app/">Instagram</a></h6>
            <h6 ><a className="subHeadingFooter" href="https://www.facebook.com/workoutlogapp">Facebook</a></h6>
            <h6 ><a className="subHeadingFooter" href="https://twitter.com/WorkoutLog_App">Twitter</a></h6>
          </div>
          <div className="social-icon">
            <a href="https://www.instagram.com/workoutlog.app/"><img src={insta} alt="" /></a>
            <a href="https://www.facebook.com/workoutlogapp"><img src={fb} alt="" /></a>
            <a href="https://twitter.com/WorkoutLog_App"><img src={twitter} alt="" /></a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "flex-end",
        }}
      >
        <h6 className="copyWrite">Copywrite 2022 - WorkoutLog.App</h6>
      </div>
    </div>
  );
}

export default Footer;
