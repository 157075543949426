import React, { useState } from "react";
import "./Wod.css";
import image from "../../assets/wod.png";
import device from "../../assets/wodDevice.png";
import Image from "react-bootstrap/Image";

const Wod = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="boxRelative">
      <Image src={device} className="device" />
      <div>
        <Image src={image} className="background" />
        <div className="blackBox"></div>
      </div>

      <div className="boxWod">
        <div className="scanBox">
          <h1 className="heading1Wod">Scan in Results</h1>
          <p className="paraWod">
            Extract the important bits. <br />
            Point your camer at the workout to quickly and Easily add it to your
            Log
          </p>
          <h6 className="smallText">Exclusivly in WorkoutLog Premium*</h6>
        </div>
        <div className="priceBox">
          <h1 className="priceText">{active === 0 ? "$4.99":"$49.99"}</h1>
          <div
            style={{
              display: "flex",
              gap: "50px",
              justifyContent:"center",
            }}
          >
            <h3 onClick={()=> setActive(0)} className={active === 0 ?"active":"inactive"} >Monthly</h3>
            <h3 onClick={()=> setActive(1)}className={active === 1 ?"active":"inactive"}>Yearly</h3>
          </div>
          <div style={{ padding: "20px 0px" }}>
            <div
              style={{
                textAlign: "start",
              }}
            >
              <h5 className="headingWodF">Unlimited Workouts</h5>
              <p className="subTitleWod">
                Create as many workouts you want!
                <br />
                Also have access to workout history and more
              </p>
            </div>
            <div
              style={{
                textAlign: "start",
              }}
            >
              <h5 className="headingWodF">Easy Scan</h5>
              <p className="subTitleWod">
                Quickly and easily scan your wortkouts off a whiteboard or from
                an image into the app.
              </p>
            </div>
            <div
              style={{
                textAlign: "start",
              }}
            >
              <h5 className="headingWodF">Customized Timers</h5>
              <p className="subTitleWod">
                Create any type of timer combination for your style of training.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wod;
